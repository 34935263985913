<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
       :src="require('@/assets/features.png')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container fill-height>
        <v-responsive class="d-flex align-center mx-auto" height="100%" max-width="700" width="100%">
          <v-row align="center" class="white--text mx-auto">
            <!--base-subheading weight="regular" title="FLEET MANAGEMENT SOLUTION" /-->

            <!--base-heading size="text-h2" title="WELCOME TO FleeTOP" weight="medium" />

            <base-body>We Love Making Things SIMPLE AND AMAZING , Get Control Over Your FLEET and SAVE On Operational Cost</base-body-->
            <div>
              <v-img height="200" width="200" :src="require(`@/assets/sticker.png`)"></v-img>
            </div>
            <v-col class="white--text text-center" cols="12" tag="h1">
              <span
                :class="[
                          $vuetify.breakpoint.smAndDown
                            ? 'display-1'
                            : 'display-2'
                        ]"
                class="font-weight-light"
              >WELCOME TO</span>
              <br />
              <span
                :class="[
                          $vuetify.breakpoint.smAndDown
                            ? 'display-3'
                            : 'display-4'
                        ]"
                class="font-weight-black"
              >FleeTOP</span>
              <span class="font-weight-black"> 
                <base-btn
                    class="align-center"
                    cols="24"
                    @click="$vuetify.goTo('#FleetopFeatures')"
                  >Discover More</base-btn></span>
                </v-col>
              </v-row>
            
          <div>
   
    <v-progress-linear
      indeterminate
      color="cyan"
    ></v-progress-linear>
  </div>
        
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "5vh" : "2vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  },
  methods: {
    openPage() {
      this.$router.replace({
        name: "FleetopFeatures"
      });
    }
  }
};
</script>
