var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"hero"}},[_c('v-img',{staticClass:"white--text",attrs:{"min-height":_vm.minHeight,"src":require('@/assets/features.png'),"gradient":"to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-responsive',{staticClass:"d-flex align-center mx-auto",attrs:{"height":"100%","max-width":"700","width":"100%"}},[_c('v-row',{staticClass:"white--text mx-auto",attrs:{"align":"center"}},[_c('div',[_c('v-img',{attrs:{"height":"200","width":"200","src":require("@/assets/sticker.png")}})],1),_c('v-col',{staticClass:"white--text text-center",attrs:{"cols":"12","tag":"h1"}},[_c('span',{staticClass:"font-weight-light",class:[
                        _vm.$vuetify.breakpoint.smAndDown
                          ? 'display-1'
                          : 'display-2'
                      ]},[_vm._v("WELCOME TO")]),_c('br'),_c('span',{staticClass:"font-weight-black",class:[
                        _vm.$vuetify.breakpoint.smAndDown
                          ? 'display-3'
                          : 'display-4'
                      ]},[_vm._v("FleeTOP")]),_c('span',{staticClass:"font-weight-black"},[_c('base-btn',{staticClass:"align-center",attrs:{"cols":"24"},on:{"click":function($event){return _vm.$vuetify.goTo('#FleetopFeatures')}}},[_vm._v("Discover More")])],1)])],1),_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }